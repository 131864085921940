<template>
  <div>
    <div class="pa-6 d-flex" style="border: 1px solid #E5E7EB; border-radius: 4px;">
      {{ appeal.denial_filename }}
      <v-spacer />
      <a
        :download="appeal.denial_filename"
        :href="appeal.denial_letter_url"
        v-show="appeal.denial_letter_id"
        target="_blank"
        class="ml-2"
        type="button"
        title="Download file"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </a>
    </div>
    <br>
    <v-row align="center" style="gap: 10px">
      <v-col cols="12" md="2">Select Year</v-col>
      <v-col>
        <v-slide-group
          show-arrows
          mandatory
          v-model="selectedYearIndex"
        >
          <v-slide-item
            v-for="year in years"
            :key="year"
            v-slot="{ active, toggle }"
          >
            <v-btn
              class="mx-2"
              :input-value="active"
              active-class="primary white--text"
              depressed
              rounded
              @click="toggle"
            >
              {{ year }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <br>
    <v-row align="center" style="gap: 10px">
      <v-col cols="12" md="2">Select Quarter</v-col>
      <v-col>
        <v-slide-group
          show-arrows
          mandatory
          v-model="selectedQuarterIndex"
        >
          <v-slide-item
            v-for="(q, i) in quarters"
            :key="q"
            v-slot="{ active, toggle }"
          >
            <v-btn
              class="mx-2"
              :disabled="disabledQuarterIndexes.includes(i)"
              :input-value="active"
              active-class="primary white--text"
              depressed
              rounded
              @click="toggle"
            >
              Q{{ q }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <br>
    <v-row align="center" style="gap: 10px">
      <v-col cols="12" md="2">Select Litigation date</v-col>
      <v-col>
        <v-menu
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              label="Date"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="litigationDate"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <br>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn
          color="primary" @click="handleUpdateDenialLetter" :loading="appealUpdateLoading"
        >Confirm</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import AppealsService from "@/services/appeals.service";

  export default {
    name: "DenialLetter",
    props: ['appeal'],
    emits: ['refetch'],
    data() {
      return {
        quarters: [1, 2, 3, 4],
        years: [2020, 2021],
        litigationDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        disabledQuarterIndexes: [0, 1, 2],
        selectedQuarterIndex: 3,
        selectedYearIndex: 0,
        appealUpdateLoading: false,
      }
    },
    methods: {
      async handleUpdateDenialLetter() {
        const quarter = this.quarters[this.selectedQuarterIndex]
        const year = this.years[this.selectedYearIndex]
        const litigation_date = this.litigationDate

        const payload = {
          quarter,
          year,
          litigation_date
        }

        const denialId = this.appeal.denial_letter_id


        this.appealUpdateLoading = true

        try {
          await AppealsService.updateDenialLetter(denialId, payload)
          this.$emit('refetch')
        } finally {
          this.appealUpdateLoading = false
        }

      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.litigationDate)
      },
    },
    watch: {
      selectedYearIndex: function(val) {
        if (val === 1) {
          this.disabledQuarterIndexes = [3]
          this.selectedQuarterIndex = 0
        } else {
          this.disabledQuarterIndexes = [0, 1, 2]
          this.selectedQuarterIndex = 3
        }
      }
    },
    created() {
      const date = new Date(this.appeal.denial_ligitation_date)

      this.litigationDate = (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      this.selectedYearIndex = this.years.indexOf(this.appeal.denial_year)
      this.selectedQuarterIndex = this.quarters.indexOf(this.appeal.denial_quarter)
    }
  };
</script>


<style scoped>

</style>