<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center" style="gap: 5px">
        <v-icon @click="handleBackClick" color="primary" style="cursor: pointer">mdi-arrow-left</v-icon>
        <h2>Appeal {{ appealId }}</h2>
        <v-spacer/>
        <h3>Client id: {{ this.clientId }}</h3>
      </v-col>
      <v-col cols="12">
        <v-divider class="mb-6" />
      </v-col>
    </v-row>
    <div v-if="appealLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row justify="space-between" v-if="appeal.denial_ligitation_date">
        <v-col cols="12" md="3">
          <Countdowns :end-date="appealTimer" :compact="true" :title="'Appeal Timer'" />
        </v-col>
        <v-col cols="12" md="8">
          <Countdowns :end-date="litigationTimer" :title="'Litigation Timer'" />
        </v-col>
      </v-row>
      <v-row >
        <v-col>
          <v-expansion-panels multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Uploaded denial letter
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <DenialLetter :appeal="appeal" @refetch="getAppeals" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Appeal letter download
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row align="center">
                  <v-col cols="12" md="4">
                    <v-checkbox label="Generate X Appeal Letter + 2848 form" />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox label="Generate Y Appeal Letter + 2848 form" />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-btn block color="primary">GENERATE</v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
              <v-expansion-panel-header>
                Signed Appeal upload
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>Signed Appeal upload</div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Appeal results
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>appeal results</div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                Document dropbox
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>doc</div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import AppealsService from "@/services/appeals.service";
  import DenialLetter from "@/components/Client/Appeals/DenialLetter.vue";
  import Countdowns from "@/components/Client/Appeals/Countdowns.vue";

  export default {
    name: 'SingleAppeal',
    components: { Countdowns, DenialLetter },
    data() {
      return {
        appealId: undefined,
        appeal: {},
        appealLoading: false,
        appealTimer: 0,
        litigationTimer: 0
      }
    },
    methods: {
      async getAppeals() {
        this.appealLoading = true
        this.appeal = (await AppealsService.getAppealById(this.appealId))[0]
        if (!this.appeal || !this.appeal.appeal_deadline) {
          this.appealLoading = false
          return
        }
        this.appealTimer = new Date(new Date(this.appeal.appeal_deadline * 1000).getTime() + 30 * 24 * 60 * 60 * 1000);

        const futureY = new Date(this.appeal.appeal_deadline * 1000);
        const appealDeadlineDate = new Date(futureY);
        const y = futureY.getFullYear() + 2;
        const newYear = new Date(futureY.setFullYear(y))
        newYear.setMonth(newYear.getMonth() - 1);
        console.log(newYear)
        this.litigationTimer = newYear;
        this.appealLoading = false
      },
      handleBackClick() {
        this.$router.push(`/clients/${ this.clientId }/appeals`);
      },
    },
    created() {
      this.appealId = this.$route.params.appealId;
      this.clientId = this.$route.params.clientId;
      this.getAppeals()
    },


  }
</script>
